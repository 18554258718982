<template>
<div ref="modalRequestConfirmation" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isRequestConfirmationModalOpen ? 1 : 0, bottom: modalRequestConfirmationHeight + 'px'}" :class="{active: isRequestConfirmationModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<SendIcon style="width: 25px; height: auto;" class="icon checkin-icon" />
			<span>Review your request</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="toggleRequestSubmittedModal()" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="info-card">
		<h3>Your Request</h3>
		<div class="info-card-wrap">
			<div class="row">
				<div class="col full">
					<span class="eyebrow">Type</span>
					<p class="p-strong">{{ request.category }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<span class="eyebrow">Port</span>
                    <p class="p-strong">{{ request.port }}</p>
				</div>
				<div class="col">
					<span class="eyebrow">Date</span>
                    <p class="p-strong">{{ request.date | moment('Do MMMM YYYY') }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col full">
					<span class="eyebrow">Request</span>
					<p class="p-strong">{{ request.request }}</p>
				</div>
			</div>
		</div>
		<Button icon="TickIcon" class="section-gap none-bottom btn btn-green btn-tick btn-square btn-icon btn-icon-right" text="Confirm & Submit Request" @click.native="submitRequest"></Button>
	</div>
	<a @click="toggleRequestSubmittedModal()" class="text-link">Oops! I'd like to change my request &raquo;</a>
</div><!-- modal -->
</template>

<script>
import Button from '@/components/Button.vue';
import GreyCloseIcon from '@/assets/close-grey.svg';
import SendIcon from '@/assets/send.svg';
import UserService from '@/services/UserService';
import moment from 'moment';
export default {
name: 'RequestConfirmationOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
			modalRequestConfirmationHeight: null
		};
	},
	components: {
		Button,
		GreyCloseIcon,
		SendIcon
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalRequestConfirmation.clientHeight;
		},
        isRequestConfirmationModalOpen () {
            return this.$store.state.isRequestConfirmationModalOpen;
        },
        request () {
            return this.$store.state.request;
        }
	},
	methods: {
        toggleRequestSubmittedModal () {
            this.$store.commit('REQUEST_CONFIRMATION_MODAL_TOGGLE');
        },
        submitRequest () {
            try {
                UserService.createRequest({
                    category: this.request.category,
                    port_id: this.request.portId,
                    date: moment(this.request.date).format('YYYY-MM-DD'),
                    vessel_name: this.request.vesselName,
                    message: this.request.request
                }).then(() => {
                    this.$store.commit('SET_REQUEST_SUBMITTED');
                });
            } catch (error) {
                console.log(error);
                // this.errorStyling = true;
            }
        }
    },
    mounted () {
		this.modalRequestConfirmationHeight = this.matchHeightModal;
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $green_dark;
				}
			}
			span {
				color: $green_dark;
			}
		}
	}
	h3 {
		color: $dark;
		font-size: 17px;
		margin-bottom: 5px;
	}
	.info-card {
		padding: 25px;
		margin-bottom: 20px;
		.info-card-wrap {
			.row {
				border-bottom: none;
			}
		}
	}
	.text-link {
		color: $mid;
	}
</style>
